<template>
  <div>
    <vx-card class="mb-base" v-if="type === 'rooms'">
      <h2 class="font-bold m-5" style="color: black">Room List</h2>

      <div class="m-5">
        <vs-table max-items="20" :pagination="roomData.length > 0" :data="roomData" >
          <template slot="thead">
            <vs-th>Logo</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Location</vs-th>
            <vs-th>Age Group</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.logo">
                <vs-avatar class="custom-avatar" :src="tr.logo"></vs-avatar>
              </vs-td>
              <vs-td :data="tr.name">
                {{ tr.name }}
              </vs-td>
              <vs-td :data="tr.location">
                {{ tr.location }}
              </vs-td>
              <vs-td :data="tr.ageGroup ? tr.ageGroup[0].ageGroup : ''">
                {{ tr.ageGroup? tr.ageGroup[0].ageGroup :''}}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>

    <vx-card class="mb-base" v-if="type === 'teachers'">
      <h2 class="font-bold m-5" style="color: black">Teachers List</h2>

      <div class="m-5">
        <vs-table max-items="20" :pagination="teacherData.length > 0" :data="teacherData" >
          <template slot="thead">
            <vs-th>Profile Image</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Email</vs-th>
            <vs-th>Contact Number</vs-th>
            <vs-th>Status</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.profileImage">
                <vs-avatar class="custom-avatar" :src="tr.profileImage"></vs-avatar>
              </vs-td>
              <vs-td :data="tr.fullName">
                {{ tr.fullName | capitalize }}
              </vs-td>
              <vs-td :data="tr.email">
                {{ tr.email }}
              </vs-td>
              <vs-td :data="tr.mobileNumber">
                {{ tr.mobileNumber }}
              </vs-td>
              <vs-td :data="tr.accountStatus">
                {{tr.accountStatus == "Active"?"Enabled":"Disabled"}}
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
    <vx-card class="mb-base" v-if="type === 'children'">
      <h2 class="font-bold m-5" style="color: black">Children List</h2>

      <div class="m-5">
        <vs-table max-items="20" :pagination="childrenData.length > 0" :data="childrenData" >
          <template slot="thead">
            <vs-th>Profile Image</vs-th>
            <vs-th>Name</vs-th>
<!--            <vs-th>Gender</vs-th>-->
            <vs-th>Age Group</vs-th>
            <vs-th>Room</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.photo">
                <vs-avatar class="custom-avatar" :src="tr.photo"></vs-avatar>
              </vs-td>
              <vs-td :data="tr.fullName">
                {{ tr.fullName | capitalize }}
              </vs-td>
<!--              <vs-td :data="tr.gender">-->
<!--                {{ tr.gender | capitalize }}-->
<!--              </vs-td>-->
              <vs-td :data="tr.ageGroupDetails.length ? tr.ageGroupDetails[0].ageGroup : ''">
                {{ tr.ageGroupDetails.length ? tr.ageGroupDetails[0].ageGroup :''}}
              </vs-td>
              <vs-td :data="tr.room ? tr.room[0].name : ''">
                {{ tr.room ? tr.room[0].name : '' }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    data() {
      return {
        roomData: [],
        teacherData: [],
        childrenData: [],
        type: this.$route.params.type,
        centerId: this.$route.params.id,
      }
    },
    methods: {
      ...mapActions("center",[
        "getTeachersList",
        "getRoomListAdmin",
        "getChildrenList"
      ]),
      fetchRoomList(id) {
        this.getRoomListAdmin(id).then(res => {
          this.roomData = res.data.data;
        })
      },
      fetchTeacherList(id){
        this.getTeachersList(id).then(res => {
          this.teacherData = res.data.data;
        })
      },
      fetchChildrenList(id) {
        this.getChildrenList(id).then(res => {
          this.childrenData = res.data.data;
        })
      }
    },
    created(){
      if(this.type === 'rooms')
        this.fetchRoomList(this.centerId);
      if(this.type === 'teachers')
        this.fetchTeacherList(this.centerId);
      if(this.type === 'children')
        this.fetchChildrenList(this.centerId);
    }
  }
</script>
